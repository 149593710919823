import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import { Auth } from "aws-amplify"
import { userPoolsClient } from "../../gatsby-browser"

const SignOut = () => {
  useEffect(() => {
    const signout = async () => {
      Auth.signOut()
        .then(async es => {
          await userPoolsClient.resetStore()
          navigate("/login")
        })
        .catch(err => {
          navigate("/")
        })
    }

    signout()
  }, [])

  return <p>Signing out...</p>
}

export default SignOut
